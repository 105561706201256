export const goals = [
  {
    type: "individual",
    items: [
      // {
      //   label: "Hello January Challenge",
      //   image: "hello-january",
      //   caption: "Save for a worry-free start to the new year.",
      //   content: {
      //     title: "Hello January Challenge",
      //     subtitle: "Short term",
      //     cta: "https://join.myxeno.com/?country=UG&goal=Hello%20January%20Challenge",
      //     objective:
      //       "Join the Hello January Challenge and become a part of a thriving community of" +
      //       " savvy investors committed to saving consistently over 60 days, ensuring a worry-free January.",
      //     benefits: [
      //       "Secure your savings to effortlessly cover January expenses.",
      //       "Financially prepare for exciting New Year's projects.",
      //       "Kickstart your savings journey with confidence.",
      //     ],
      //     elements: [
      //       "Say goodbye to the stress of being financially unprepared for January.",
      //       "Watch your savings grow with added interest during the challenge.",
      //       "Invite friends and family via your referral code and earn.",
      //       "Win rewards for beating our savings targets."
      //     ],
      //   },
      // },
      {
        label: "Child’s Education",
        image: "education",
        caption: "Plan and conveniently fund child’s school years.",
        content: {
          title: "Child’s Education",
          subtitle: "Long term",
          cta: "https://join.myxeno.com/?country=UG&goal=3",
          objective:
            "XENO helps you to conveniently invest your savings to comfortably fund your child’s education",
          benefits: [
            "Time till the start of education",
            "Plan for each child separately",
            "Plan for each education level separately",
          ],
          elements: [
            "You want to secure your child’s future",
            "You don’t want to borrow to pay fees",
            "You want to track progress towards your goal",
          ],
        },
      },
      {
        label: "Build Wealth",
        image: "wealth",
        caption: "Invest your savings to live comfortably.",
        content: {
          title: "Build Wealth",
          subtitle: "Long term, medium term",
          cta: "https://join.myxeno.com/?country=UG&goal=2",
          objective:
            "XENO helps you to invest your savings to accumulate sufficient resources to live a comfortable life",
          benefits: [
            "You have a medium to long term objective",
            "You want to grow your money gradually",
            "You want to track progress towards your goal",
          ],
          elements: [
            "Your investment period",
            "The target amount you want to accumulate",
            "Current size of your assets",
          ],
        },
      },
      // {
      //   label: "Tulumbe Morocco",
      //   image: "tulumbeMorocco",
      //   caption: "Travel to support Cranes at AFCON 2025.",
      //   content: {
      //     title: "Tulumbe Morocco",
      //     subtitle: "Short term",
      //     cta: "https://join.myxeno.com/?country=UG&goal=23",
      //     objective:
      //       "XENO helps you to invest your savings to accumulate sufficient resources to live a comfortable life",
      //     benefits: [
      //       "You have a medium to long term objective",
      //       "You want to grow your money gradually",
      //       "You want to track progress towards your goal",
      //     ],
      //     elements: [
      //       "Your investment period",
      //       "The target amount you want to accumulate",
      //       "Current size of your assets",
      //     ],
      //   },
      // },
      {
        label: "Emergency Fund",
        image: "emergency",
        caption: "Plan and save for the unexpected.",
        content: {
          title: "Emergency Fund",
          subtitle: "Short term",
          cta: "https://join.myxeno.com/?country=UG&goal=5",
          objective:
            "XENO helps you to build up savings to solve any sudden short term financial emergency without going into debt or raiding your retirement pot",
          benefits: [
            "You want to guard against sudden loss of income",
            "You want to easily solve sudden financial needs",
            "You don’t want to borrow for every emergency",
          ],
          elements: [
            "How big you want your emergency fund",
            "The starting amount",
            "How much more you can save",
          ],
        },
      },
      {
        label: "Other Goal",
        image: "other",
        caption: "Plan for a wedding, vacation, Christmas.",
        content: {
          title: "Other Goal",
          subtitle: "Long term, medium term",
          cta: "https://join.myxeno.com/?country=UG&goal=9",
          objective:
            "XENO helps you grow personal savings and accumulate sufficient resources for any other goal.",
          benefits: [
            "You have any other goals",
            "You want save and invest any amount, anytime",
            "How much more you can save",
          ],
          elements: [
            "Investment period",
            "Desired amount you want to accumulate",
            "How much more you can save",
          ],
        },
      },
      {
        label: "Secure Retirement",
        image: "retirement",
        caption: "Plan for your sunset years by investing.",
        content: {
          title: "Secure Retirement",
          subtitle: "Long term",
          cta: "https://join.myxeno.com/?country=UG&goal=1",
          objective:
            "XENO helps you to accumulate a retirement pot that will generate sufficient cash flows for a comfortable retirement",
          benefits: [
            "You don’t already have retirement pot",
            "You want to save more for your retirement",
            "You want to track progress towards retirement",
          ],
          elements: [
            "Time to retirement",
            "Desired lifestyle in retirement",
            "Current size of retirement pot",
          ],
        },
      },
      {
        label: "Own a Home",
        image: "home",
        caption: "Invest your savings towards a family home.",
        content: {
          title: "Own a Home",
          subtitle: "Short term, medium term",
          cta: "https://join.myxeno.com/?country=UG&goal=4",
          objective:
            "XENO helps you to accumulate savings you need to either buy or build a family home without going into significant debt",
          benefits: [
            "You want to avoid borrowing huge amounts",
            "You want to save and invest gradually till you have the amount needed to own a home",
          ],
          elements: [
            "Time till purchase or start of construction",
            "Desired amount you want to spend on home",
            "How much you are starting with",
          ],
        },
      },
      // {
      //   label: "Income Drawdown",
      //   image: "income",
      //   caption: "Create regular cashflows from your assets.",
      //   content: {
      //     title: "Income Drawdown",
      //     subtitle: "Short term",
      //     cta: "https://join.myxeno.com/?country=UG&goal=INCOME",
      //     objective:
      //       "XENO helps you to plan an orderly monthly drawdown from your investment and ensure your money lasts for the desired period",
      //     benefits: [
      //       "You have a large asset base",
      //       "You want an orderly draw down plan",
      //       "You don’t want to run out of money",
      //     ],
      //     elements: [
      //       "Current size of investment",
      //       "Desired amount you want to drawdown",
      //       "Desired time period you want it to last",
      //     ],
      //   },
      // },
      {
        label: "Family Goal",
        image: "family",
        caption: "Save up with loved ones for collective goals.",
        content: {
          title: "Family Goal",
          subtitle: "Long term",
          cta: "https://join.myxeno.com/?country=UG&goal=20",
          objective:
            "We help you save and grow to achieve your goals collectively as a family",
          benefits: [
            "You have family goals",
            "You want your family savings invested",
            "You want members to track their contributions, performance, and progress",
          ],
          elements: [
            "Investment period",
            "Desired amount you want to accumulate",
            "How much each member can save",
          ],
        },
      },
    ],
  },
  {
    type: "institution",
    items: [
      {
        label: "Corporate",
        image: "corporate",
        caption: "Invest your company's cash reserves.",
        content: {
          title: "Corporate",
          subtitle: "Any term",
          cta: "https://join.myxeno.com/?country=UG&goal=13",
          objective:
            "XENO helps you invest excess corporate cash to meet short, medium, and long term goals",
          benefits: [
            "You want to put idle funds to work",
            "You want invest in treasury bills, bonds",
          ],
          elements: ["Investment period", "Liquidity requirements"],
        },
      },
      {
        label: "Retirement Schemes",
        image: "scheme",
        caption: "Invest member savings transparently.",
        content: {
          title: "Retirement Schemes",
          subtitle: "Medium term, Long term",
          cta: "https://join.myxeno.com/?country=UG&goal=15",
          objective:
            "XENO helps you to invest your savings to accumulate sufficient resources to live a comfortable life",
          elements: [
            "Investment period",
            "Desired amount you want to accumulate",
            "Current size of retirement pot",
            "How much more you can contribute",
          ],
          benefits: [
            "Your company provides retirement benefits",
            "Your Scheme values transparent investment",
            "You want members to track their contributions, performance, and progress",
          ],
        },
      },
      {
        label: "SACCOs",
        image: "sacco",
        caption: "Manage and invest your group savings.",
        content: {
          title: "SACCOs",
          subtitle: "Any term",
          cta: "https://join.myxeno.com/?country=UG&goal=16",
          objective:
            "XENO helps SACCOs provide savings and investment products to help members reach their financial goals",
          benefits: [
            "You have collective SACCO goals",
            "You want your member savings invested",
            "If you want members to track their contributions, performance, and progress",
          ],
          elements: [
            "Investment period",
            "Desired amount you want to accumulate",
            "How much each member can save",
          ],
        },
      },
      {
        label: "Investment Clubs",
        image: "club",
        caption: "Plan, save, and invest for collective goals.",
        content: {
          title: "Investment Clubs",
          subtitle: "Any term",
          cta: "https://join.myxeno.com/?country=UG&goal=19",
          objective:
            "XENO helps Clubs and their members to reach their collective financial goals",
          benefits: [
            "You have collective Club goals",
            "You want your member savings invested",
            "If you want members to track their contributions, performance, and progress",
          ],
          elements: [
            "Investment period",
            "Desired amount you want to accumulate",
            "How much each member can save",
          ],
        },
      },
    ],
  },
]
