import React from "react"
import styled from "styled-components"
import Pattern from "../../shared/pattern"
import Text from "../../shared/text"
import Icon from "../../shared/icon"
import Chevron from "../../shared/chevron"
import { vw } from "../../../utilities/pxtovw"
import Flex from "../../shared/flex"
import { useGlobalDispatchContext } from "../../../utilities/context"
import { logEvent } from "../../../utilities/logevent"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const Image = styled.div`
  height: ${vw(248)};
  position: relative;
  width: 75%;
  transition: transform 0.75s cubic-bezier(0.22, 1, 0.36, 1);

  .xeno-chevy {
    height: 4.5rem;
    width: 3rem;
    right: -1.25rem;
    top: 10%;
    z-index: 2;
  }

  @media (max-width: 766px) {
    height: 35vw;
    width: 30%;
    margin-bottom: 2.25rem;
    margin-left: 2rem;
  }
`

const Box = styled.button`
  position: relative;

  &:hover {
    .image {
      transform: translate3d(0, -1rem, 0);
    }

    .text {
      color: var(--c-white);

      &:after {
        opacity: 1;
      }

      path {
        fill: #fff;
      }

      svg {
        transform: translate3d(0.5rem, 0, 0);
      }
    }
  }
`

const Card = styled.div`
  padding: 1.125rem;
  color: var(--c-blue-200);
  position: absolute;
  top: 50%;
  left: 25%;
  width: 75%;
  text-align: left;
  z-index: 0;

  > div {
    position: relative;
    z-index: 2;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
  }

  &:before {
    z-index: 0;
    background-color: var(--c-mint-100);
  }

  &:after {
    z-index: 1;
    background-color: var(--c-blue-200);
    opacity: 0;
  }

  p {
    line-height: 1.4;
    font-size: 0.75rem;
  }

  .xn-pattern {
    width: 2.8rem;
    height: 1.875rem;
    top: -3rem;
    right: 16%;
    position: absolute;
  }

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
    padding-bottom: 1.5rem;
    letter-spacing: 0.2px;

    &.subsection {
      padding-bottom: 0.5rem;
      padding-top: 2rem;
    }
  }

  @media (max-width: 766px) {
    position: absolute;
    top: 2vw;
    z-index: 10;
    width: calc(75% - 4rem);
    left: calc(25% + 2rem);

    h3 {
      padding-bottom: 1rem;
    }

    .xn-pattern {
      display: none;
    }
  }
`

const Button = styled.div`
  margin-top: 2rem;
  background-color: var(--c-blue-200);
  display: inline-block;
  color: var(--c-mint-100);
  padding: 0.35rem 0.75rem;
  border-radius: 10rem;

  path {
    fill: var(--c-mint-100);
  }

  svg {
    width: 0.5rem;
    height: 0.5rem;
    transition: transform 0.3s;
  }

  span {
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  @media (max-width: 766px) {
    margin-top: 1.5rem;
  }
`

const addUnderscores = text => {
  return text.replace(/ /g, "_")
}

const Ele = ({ label, image, caption, content }) => {
  const dispatch = useGlobalDispatchContext()
  const handleClick = () => {
    dispatch({
      type: "SET_SHOW_MODAL",
      content: content,
      show: true,
    })

    logEvent("Home", "View " + label)
  }

  return (
    <Box onClick={() => handleClick()}>
      <Image className="image">
        <Chevron />
        <GatsbyImage
          image={getImage(image)}
          alt={"XENO " + image.name + " goal"}
        />
      </Image>
      <Card className="text">
        <div>
          <Pattern type="dot" size="4" />
          <h3>{label}</h3>
          <Text>
            <p>{caption}</p>
          </Text>
          <Button
            className="button"
            id={addUnderscores(label.toLowerCase()) + "_start_button"}
          >
            <Flex center>
              <span>Start</span>
              <Icon title="chevron-black" />
            </Flex>
          </Button>
        </div>
      </Card>
    </Box>
  )
}
export default Ele
